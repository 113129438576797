<template>
  <mdb-container>
    <mdb-col>
      <div class="mt-5 sub_content">
        <h3>계정 관리</h3>
      </div>
      <div class="card mx-xl-5 mt-3 p-3 mb-5">
        <div class="card-body">
          <form>
            <label for="email" class="grey-text">이메일</label>
            <input type="email" id="email" class="form-control" v-model="email">
            <br>
            <label for="writer" class="grey-text">패스워드</label>
            <input type="password" id="writer" class="form-control" v-model="password">
            <br>
            <div class="text-right mt-4">
              <mdb-btn outline="success" small @click="updatePassword" icon="lock">패스워드변경</mdb-btn>
            </div>
          </form>
        </div>
      </div>
    </mdb-col>
  </mdb-container>
</template>

<script>
import {mdbContainer, mdbCol, mdbBtn} from 'mdbvue';
import firebase from "firebase/app";

export default {
  name: 'AccountManager',
  components: {
    mdbContainer,
    mdbCol,
    mdbBtn,
  },
  data() {
    return {
      email: this.$store.state.user_email,
      password: "",
    };
  },
  methods: {
    updatePassword() {
      const self = this
      function getPassword() {
        return self.password;
      }
      const user = firebase.auth().currentUser;
      const newPassword = getPassword();

      user.updatePassword(newPassword).then(() => {
        alert('패스워드가 변경되었습니다.')
      }).catch((error) => {
        self.firebaseError(error)
      });
      // [END auth_update_password]
    },

    firebaseError(error) {
      if ('auth/invalid-verification-code' === error.code) {
        alert('인증번호가 유효하지 않습니다.');
      } else if ('auth/session-expired' === error.code) {
        alert('인증번호가 만료되었습니다.');
      } else if ('auth/too-many-requests' === error.code) {
        alert('잠시 후 다시 시도해 주세요.');
      } else if ('Error: The email address is badly formatted.' === error.code) {
        alert('이메일은 필수입니다.');
      } else if ('auth/requires-recent-login' === error.code) {
        alert('이 작업은 민감하며 최근 인증이 필요합니다. 이 요청을 다시 시도하기 전에 다시 로그인하십시오.');
      }else {
        console.log(error);
      }
    },
  }
}
</script>

<style scoped>
.sub_content {
  text-align: center;
}

.sub_content h3 {
  font-weight: bold;
}

.file-input-doc {
  margin-top: 0;
  margin-bottom: 0;
}

.file-input-img {
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  display: block;
}

</style>